<template>
  <v-dialog
    persistent
    v-model="profileDialogEdit"
    :width="screenWidth + '%'"
  >
    <div class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <p class="text-title mon-bold">{{ texts.userprofile.dialogEdit.textTitle }}</p>
      <div class="mt-9">
        <v-text-field
          v-model="profileName"
          type="text"
          :label="texts.userprofile.dialogEdit.textHeader1"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular"
          :placeholder="texts.userprofile.dialogEdit.textHeader1"
          persistent-placeholder
          maxLength="40"
        ></v-text-field>
        <v-text-field
          v-model="profileLastname"
          type="text"
          :label="texts.userprofile.dialogEdit.textHeader2"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mt-5"
          :placeholder="texts.userprofile.dialogEdit.textHeader2"
          persistent-placeholder
          maxLength="40"
        ></v-text-field>
        <v-text-field
          v-model="profileEmail"
          type="text"
          :label="texts.userprofile.dialogEdit.textHeader3"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mt-5"
          :placeholder="texts.userprofile.dialogEdit.textHeader3"
          persistent-placeholder
          maxLength="85"
        ></v-text-field>
        <v-text-field
          v-model="profilePosition"
          type="text"
          :label="texts.userprofile.dialogEdit.textHeader4"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mt-5"
          :placeholder="texts.userprofile.dialogEdit.textHeader4"
          persistent-placeholder
          maxLength="40"
        ></v-text-field>
        <v-text-field
          v-model="profilePhone"
          type="text"
          :label="texts.userprofile.dialogEdit.textHeader5"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mt-5"
          :placeholder="texts.userprofile.dialogEdit.textHeader5"
          persistent-placeholder
          maxLength="13"
          @keypress="soloNumbers"
        ></v-text-field>
      </div>
      <div
        v-if="isResponsive"
        class="mt-6"
      >
        <v-btn
          elevation="0"
          @click="saveChanges"
          :loading="bLoading"
          :disabled="!validateForm"
          class="button-primary mon-bold"
          style="width: 100%;"
        >
          {{ texts.userprofile.dialogEdit.buttonSave }}
        </v-btn>
        <v-btn
          elevation="0"
          @click="closeDialog"
          class="button-secondary mon-regular mt-5"
          style="width: 100%;"
        >
          {{ texts.userprofile.dialogEdit.buttonCancel }}
        </v-btn>
      </div>
      <div
        v-else
        class="mt-6 display-flex align-items-center"
      >
        <v-btn
          elevation="0"
          @click="closeDialog"
          class="button-secondary mon-regular"
          style="width: 45%;"
        >
          {{ texts.userprofile.dialogEdit.buttonCancel }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          @click="saveChanges"
          :loading="bLoading"
          :disabled="!validateForm"
          class="button-primary mon-bold"
          style="width: 45%;"
        >
          {{ texts.userprofile.dialogEdit.buttonSave }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "UserProfileDialogEditLayout",
  data() {
    //VARIABLES
    return {
      profileName: "",
      profileLastname: "",
      profileEmail: "",
      profilePosition: "",
      profilePhone: "",
      screenWidth: 0,
      texts: "",
      bLoading: false,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.texts = FILE.userprofile[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    closeDialog: function () {
      this.$store.commit("setProfileDialogEdit", {
        active: false,
        arr: [],
      });
      this.profileName = "";
      this.profileLastname = "";
      this.profileEmail = "";
      this.profilePosition = "";
      this.profilePhone = "";
    },
    saveChanges: function () {
      this.bLoading = true;

      const payload = {
        sName: this.profileName,
        sLastname: this.profileLastname,
        sEmail: this.profileEmail,
        sPositionName: this.profilePosition,
        sPhoneNumber: this.profilePhone,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/employees/${this.$store.state.sUserId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.$store.commit(
            "setFullName",
            this.profileName + " " + this.profileLastname
          );
          this.closeDialog();
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    // return create contact dialog state
    profileDialogEdit() {
      return this.$store.state.profileDialogEdit;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    validateForm: function () {
      return (
        this.profileName !== "" &&
        this.profileLastname !== "" &&
        this.profileEmail !== "" &&
        this.profilePosition !== "" &&
        this.profilePhone !== "" &&
        this.profileName !== null &&
        this.profileLastname !== null &&
        this.profileEmail !== null &&
        this.profilePosition !== null &&
        this.profilePhone !== null
      );
    },
  },
  watch: {
    profileDialogEdit() {
      if (this.profileDialogEdit) {
        this.profileName = this.$store.state.profileDialogEditArr.sName;
        this.profileLastname = this.$store.state.profileDialogEditArr.sLastname;
        this.profileEmail = this.$store.state.profileDialogEditArr.sEmail;
        this.profilePosition =
          this.$store.state.profileDialogEditArr.sPositionName;
        this.profilePhone = this.$store.state.profileDialogEditArr.sPhoneNumber;
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.userprofile[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 20px;
}

.text-description {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}
</style>